function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {value: t, enumerable: true, configurable: true, writable: true}) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return typeof i == "symbol" ? i : i + "";
}
function _toPrimitive(t, r) {
  if (typeof t != "object" || !t)
    return t;
  var e = t[Symbol.toPrimitive];
  if (e !== void 0) {
    var i = e.call(t, r || "default");
    if (typeof i != "object")
      return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (r === "string" ? String : Number)(t);
}
import {importRetry} from "../../utils/import-retry.js";
import {resolveOwnUserProfile} from "../../scenes/user-profile/resolve-own-user-profile.js";
export class RouteBuilder {
  get appRoutes() {
    return [
      {
        path: "donate",
        pathMatch: "full",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'donatePage' */
          "../../scenes/donate/donate-page.js"
        )),
        setup: (component, info) => {
          const donatePage = component;
          const donationFormSlot = document.createElement("slot");
          donationFormSlot.name = "donation-form-slot";
          donationFormSlot.slot = "donation-form-slot";
          setTimeout(() => {
            donatePage.appendChild(donationFormSlot);
            donatePage.appServices = this.appServices;
          }, 0);
        }
      },
      {
        path: "/details/:id",
        component: () => import("../../scenes/details-page-router.js"),
        setup: (component, info) => {
          const identifier = decodeURIComponent(info.match.params.id);
          const detailsPageRouter = component;
          detailsPageRouter.appServices = this.appServices;
          detailsPageRouter.identifier = identifier;
        }
      },
      {
        path: "/collection/:id",
        resolve: (info) => {
          const identifier = info.match.params.id;
          history.replaceState(history.state, "", `/details/${identifier}${window.location.search}`);
          return false;
        }
      },
      {
        path: "/account/$",
        resolve: this.accountRouteResolver
      },
      {
        path: "/search",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'searchPage' */
          "../../scenes/search-page/search-page.js"
        )),
        setup: (component, info) => {
          const searchPage = component;
          searchPage.appServices = this.appServices;
        }
      },
      {
        path: "/$",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'homePage' */
          "../../scenes/home/home-page.js"
        )),
        setup: (component, info) => {
          const homePage = component;
          homePage.appServices = this.appServices;
        }
      },
      {
        path: "/forgot-password",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'forgotPassword' */
          "../../scenes/account/forgot-password.js"
        )),
        setup: (component, info) => {
          const forgotPassword = component;
          forgotPassword.appServices = this.appServices;
          forgotPassword.baseHost = "";
        }
      },
      {
        path: "/about/:page",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          const page = decodeURIComponent(info.match.params.page);
          if (page) {
            staticPage.page = page;
          }
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/about/$",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.appServices = this.appServices;
          staticPage.page = "about";
        }
      },
      {
        path: "/bookserver",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.appServices = this.appServices;
          staticPage.page = "bookserver";
        }
      },
      {
        path: "/legal/affidavit",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "legal-affidavit";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/legal/faq",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "legal-faq";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/legal/$",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "legal";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/projects",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "projects";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/scanning",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "scanning";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/web/petabox",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "petabox";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/web/sflan",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'staticPage' */
          "../../scenes/static-page/static-page.js"
        )),
        setup: (component, info) => {
          const staticPage = component;
          staticPage.page = "sflan";
          staticPage.appServices = this.appServices;
        }
      },
      {
        path: "/labs/search/smart-row",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'searchPage' */
          "../../scenes/search-page/search-page.js"
        )),
        setup: (component, info) => {
          const searchPage = component;
          searchPage.appServices = this.appServices;
          searchPage.smartCarouselType = "single";
        }
      },
      {
        path: "/labs/search/smart-carousels",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'searchPage' */
          "../../scenes/search-page/search-page.js"
        )),
        setup: (component, info) => {
          const searchPage = component;
          searchPage.appServices = this.appServices;
          searchPage.smartCarouselType = "multi";
        }
      },
      {
        path: "/labs/search/smart-bubbles",
        component: () => importRetry(() => import(
          /* webpackChunkName: 'searchPage' */
          "../../scenes/search-page/search-page.js"
        )),
        setup: (component, info) => {
          const searchPage = component;
          searchPage.appServices = this.appServices;
          searchPage.showSmartFacetBar = true;
        }
      }
    ];
  }
  constructor(appServices) {
    _defineProperty(this, "appServices", void 0);
    _defineProperty(this, "accountRouteResolver", (info) => {
      resolveOwnUserProfile(this.appServices);
      return false;
    });
    this.appServices = appServices;
  }
}
